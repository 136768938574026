import { Link } from 'react-router-dom';

import '../styles/Footer.css';

function Footer(){

    return(
    <footer className="footer">
        
        <div className="footer-container">
            <div className="footer-wrapper">
                <h1>RYON ASSET</h1>
            </div>
            <div className="footer-wrapper">
            <p className="footer-wrapper-title">RYON ASSET</p>
                <ul className="footer-wrapper-links">
                <Link to="/">
                <li>Home</li>
                </Link>
                <Link to="/blog">
                <li>Blog</li>
                </Link>
                <Link to="/about">
                <li>About Us</li>
                </Link>
                </ul>
            </div>
            <div className="footer-wrapper">
            <p className="footer-wrapper-title">I-EARN GOLD</p>
            <ul className="footer-wrapper-links">
                <li></li>
            </ul>
            </div>
            <div className="footer-wrapper">
            <p className="footer-wrapper-title">HELP</p>
            <ul className="footer-wrapper-links">
                <a href="mailto:admin@ryonasset.com">
                <li>Contact Us</li>
                </a>
            </ul>
            </div>
        </div>
        
        <p className='copyright'>© { new Date().getFullYear() }. RYON ASSET All rights reserved.</p>
    
    </footer>
    );
}

export default Footer;